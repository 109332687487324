import { createSlice } from '@reduxjs/toolkit'
import {
  modifier as modifierEcoSc,
  supprimer as supprimerEcoSc,
} from 'src/views/scEco/det/scEcoSlice'

const initialState = [
  {
    name: 'Simulation n°1',
    length: '5',
    eco_sc: 'ORSA',
    business: 'ORSA',
    expenses: 'Hypothèse de Frais n°1',
    fin_strat: 'ORSA',
    profit_sharing: 'ORSA',
    output: [],
  },
  {
    name: 'Simulation n°2',
    length: '10',
    eco_sc: 'ORSA',
    business: 'ORSA',
    expenses: 'Hypothèse de Frais n°1',
    fin_strat: 'ORSA',
    profit_sharing: 'ORSA',
    output: [],
  },
]
// scenario eco det
export const rwDetSlice = createSlice({
  name: 'rwDetList',
  initialState,
  reducers: {
    importer: (state, action) => (state = [...state, action.payload]),
    dupliquer: (state, action) => {
      var today = new Date()
      var time = today.toUTCString()
      const duplicatedRows = action.payload.map((e) => ({
        ...e,
        name: e.name + '_dupliqué_' + time,
      }))
      return duplicatedRows.concat(state)
    },
    supprimer: (state, action) =>
      (state = state.filter((el) => !action.payload.map((e) => e.name).includes(el.name))),
    modifier: (state, action) => {
      // modifier ou ajouter
      const oldSimu = action.payload.old
      const newSimu = action.payload.new

      // si modifier
      if (oldSimu.name !== '') {
        return state.map((e) => (e.name === oldSimu.name ? newSimu : e))
      } else {
        return [...state, newSimu]
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(supprimerEcoSc, (state, action) => {
      const delNames = action.payload.map((e) => e.name)
      return state.map((e) => (delNames.includes(e.eco_sc) ? { ...e, eco_sc: '' } : e))
    })

    builder.addCase(modifierEcoSc, (state, action) =>
      action.payload.old.name === action.payload.new.name
        ? state
        : state.map((e) =>
            e.eco_sc === action.payload.old.name ? { ...e, eco_sc: action.payload.new.name } : e,
          ),
    )
  },
})

export const { importer, dupliquer, supprimer, modifier } = rwDetSlice.actions
export const getRwDetList = (state) => state.rwDetList // in store

export default rwDetSlice.reducer
