import React, { Suspense, createContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { CToaster } from '@coreui/react'
import { MessageToast } from 'src/views/commun/Components'
import { getLabels } from './components/slices/labelsSlice'

// python script
import analyseTirages from 'src/python/analyse_tirages.py'
import fcts from 'src/python/fcts.py'
// economic
import curve_fitting from 'src/python/esg/curve_fitting.py'
// liability classes
import epargne_euro from 'src/python/passif/epargne_euro.py'
import passifs from 'src/python/passif/passif_classes.py'
// asset classes
import asset from 'src/python/asset/asset.py'
import equity from 'src/python/asset/equity.py'
import property from 'src/python/asset/property.py'
import fixed_income from 'src/python/asset/fixed_income.py'
import fixed_rate_bond from 'src/python/asset/fixed_rate_bond.py'
// section classes
import section from 'src/python/section/section.py'

const PyodideContext = createContext()
const ToastContext = createContext()

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages

const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const labels = useSelector(getLabels)
  // toast to let user know whenever python is ready to use
  const [toast, addToast] = useState(0)
  const toaster = useRef()

  // dictionary visibility
  const [dictVisible, setDictVisible] = useState(false)
  // Copilot visibility
  const [copilotVisible, setCopilotVisible] = useState(false)
  // Lucky sheet
  const [showSpreadsheet, setShowSpreadsheet] = useState(false)
  // documentation
  const [docuVisible, setDocuVisible] = useState(false)

  //////////////////////////
  // Python console
  //////////////////////////
  const [showConsole, setShowConsole] = useState(false)
  //terminal output&
  const [terminalLineData, setTerminalLineData] = useState([
    'Exécuter votre code Python et voir le résultat ci-dessous !',
  ])
  // pyodide
  const pyodide = useRef(null)
  const [isPyodideLoading, setIsPyodideLoading] = useState(true)
  const indexURL = 'https://cdn.jsdelivr.net/pyodide/v0.25.1/full/'

  // load pyodide wasm module and initialize it
  useEffect(() => {
    const loadP = async () => {
      pyodide.current = await window.loadPyodide({
        indexURL,
        stdout: (s) => setTerminalLineData((e) => [...e, s]),
        stderr: (s) => setTerminalLineData((e) => [...e, s]),
      })
      await pyodide.current.loadPackage(['numpy', 'pandas', 'scipy'])

      // read the python code in text format into 'pythonCode' variable
      const pyAnalyseTirages = await (await fetch(analyseTirages)).text()
      const pyFcts = await (await fetch(fcts)).text()
      const pyPassifs = await (await fetch(passifs)).text()
      const pyEpargneEuro = await (await fetch(epargne_euro)).text()
      const pyAsset = await (await fetch(asset)).text()
      const pyEquity = await (await fetch(equity)).text()
      const pyProperty = await (await fetch(property)).text()
      const pyFixedIncome = await (await fetch(fixed_income)).text()
      const pyFixedRateBond = await (await fetch(fixed_rate_bond)).text()
      const pyCurveFitting = await (await fetch(curve_fitting)).text()
      const pySection = await (await fetch(section)).text()
      // run python code
      await pyodide.current.runPythonAsync(pyCurveFitting)
      //await pyodide.current.runPythonAsync(pyActifs)
      await pyodide.current.runPythonAsync(pyFcts)
      await pyodide.current.runPythonAsync(pyPassifs)
      await pyodide.current.runPythonAsync(pyEpargneEuro)
      await pyodide.current.runPythonAsync(pyAnalyseTirages)
      await pyodide.current.runPythonAsync(pyAsset)
      await pyodide.current.runPythonAsync(pyEquity)
      await pyodide.current.runPythonAsync(pyProperty)
      await pyodide.current.runPythonAsync(pyFixedIncome)
      await pyodide.current.runPythonAsync(pyFixedRateBond)
      await pyodide.current.runPythonAsync(pySection)

      // updating value of isPyodideLoading triggers second useEffect
      setIsPyodideLoading(false)

      addToast(MessageToast('success', labels.pythonLoaded, labels.pythonMessage))
    }
    loadP()
  }, [pyodide])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <CToaster ref={toaster} push={toast} placement="top-end" />

        <PyodideContext.Provider
          value={{
            pyodide,
            isPyodideLoading,
            setIsPyodideLoading,
            terminalLineData,
            showConsole,
            setShowConsole,
            showSpreadsheet,
            setShowSpreadsheet,
            dictVisible,
            setDictVisible,
            copilotVisible,
            setCopilotVisible,
            docuVisible,
            setDocuVisible,
          }}
        >
          <ToastContext.Provider value={{ addToast }}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </ToastContext.Provider>
        </PyodideContext.Provider>
      </Suspense>
    </HashRouter>
  )
}
//}

export { PyodideContext, ToastContext }
export default App
