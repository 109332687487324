import { createSlice } from '@reduxjs/toolkit'
import { setLang } from './langSlice'
import { objFromArrays } from 'src/views/commun/UsefulFunc'
import { AG_GRID_LOCALE_FR } from 'src/views/commun/Locale_FR'
import { AG_GRID_LOCALE_VN } from 'src/views/commun/Locale_VN'

// technical dictionary
export const techDict = {
  projStep: {
    en: 'projection step',
    fr: 'pas de projection',
    vn: 'Đơn vị thời gian mô phỏng',
  },

  parameters: { en: 'parameters', fr: 'paramètres', vn: 'tham số', to: '#/parameters/general' },
  calibParam: { en: 'calibrated parameters', fr: 'paramètres calibrés', vn: 'tham số hiệu chỉnh' },
  interExtra: {
    en: 'interpolation/extrapolation',
    fr: 'interpolation/extrapolation',
    vn: 'nội suy/ngoại suy',
    to: '#/eco/yield-curves',
  },
  duration: { en: 'duration', fr: 'duration', vn: 'duration' },
  // objects
  curves: {
    en: 'yield curves',
    fr: 'courbes de taux',
    vn: 'đường cong lãi suất',
    to: '#/eco/yield-curves',
  },
  curve: {
    en: 'yield curve',
    fr: 'courbe de taux',
    vn: 'đường cong lãi suất',
    to: '#/eco/yield-curves',
  },
  shocked_curve: {
    en: 'the shocked yield curve',
    fr: 'la courbe de taux choquée',
    vn: 'đường cong lãi suất sau sốc',
    to: '#/eco/yield-curves',
  },
  curve_wo_va: {
    en: 'yield curve without VA',
    fr: 'courbe de taux sans VA',
    vn: 'đường cong lãi suất không VA',
    to: '#/eco/yield-curves',
  },
  curve_va: {
    en: 'yield curve with VA',
    fr: 'courbe de taux avec VA',
    vn: 'đường cong lãi suất với VA',
    to: '#/eco/yield-curves',
  },
  sw: {
    en: 'Smith-Wilson',
    fr: 'Smith-Wilson',
    vn: 'Smith-Wilson',
    to: '#/eco/yield-curves',
  },
  nss: {
    en: 'Nelson-Siegel-Svensson',
    fr: 'Nelson-Siegel-Svensson',
    vn: 'Nelson-Siegel-Svensson',
    to: '#/eco/yield-curves',
  },
  spline: {
    en: 'Spline',
    fr: 'Spline',
    vn: 'Spline',
    to: '#/eco/yield-curves',
  },

  shiftedShock: {
    en: 'uniform shock',
    fr: 'choc uniforme',
    vn: 'sốc đồng nhất',
    to: '#/eco/yield-curves',
  },

  scenario: { en: 'scenario', fr: 'scénario', vn: 'kịch bản' },
  scenarios: { en: 'scenarios', fr: 'scénarios', vn: 'kịch bản', to: '#/eco/deterministic' },
  yield: { en: 'yield', fr: 'taux', vn: 'lãi suất', to: '#/eco/yield-curves' },

  maturity: { en: 'maturity', fr: 'maturité', vn: 'kỳ hạn' },
  per_maturity: { en: 'per maturity', fr: 'par maturité', vn: 'theo kỳ hạn' },
  last_maturity: { en: 'last maturity', fr: 'dernière maturité', vn: 'kỳ hạn cuối' },
  forward: {
    en: 'foward rate 1m1y',
    fr: 'taux forward 1m1y',
    vn: 'lợi suất forward kỳ hạn 1m1y',
    to: '#/eco/yield-curves',
  },
  forward_wo_va: {
    en: 'foward rate 1m1y without VA',
    fr: 'taux forward 1m1y sans VA',
    vn: 'lợi suất kỳ hạn 1m1y không VA',
    to: '#/eco/yield-curves',
  },
  forward_va: {
    en: 'foward rate 1m1y Curve with VA',
    fr: 'taux forward 1m1y avec VA',
    vn: 'lợi suất kỳ hạn 1m1y với VA',
    to: '#/eco/yield-curves',
  },
  rfr: {
    en: 'risk free rate',
    fr: 'taux sans risque',
    vn: 'lãi suất không rủi ro',
    to: '#/eco/yield-curves',
  },

  annualInterestRate: {
    en: 'annual interest rate',
    fr: "taux d'intérêt annuel",
    vn: 'Lãi suất năm',
  },
  monthlyInterestRate: {
    en: 'monthly interest rate',
    fr: "taux d'intérêt mensuel",
    vn: 'Lãi suất tháng',
  },

  business: {
    en: 'business',
    fr: 'business',
    vn: 'dự phóng kinh doanh',
  },
  expenses: {
    en: 'expenses',
    fr: 'frais',
    vn: 'chi phí',
  },
  profit_sharing: {
    en: 'profit sharing',
    fr: 'participations aux Bénéfices',
    vn: 'chia lợi nhuận',
  },
  fin_strat: {
    en: 'financial strategy',
    fr: 'stratégie financière',
    vn: 'chiến lược tài chính',
  },

  // economic indicators
  eco_sc: {
    en: 'economic scenario',
    fr: 'scénario économique',
    vn: 'kịch bản kinh tế',
  },
  spread_a: {
    en: 'spread A',
    fr: 'spread A',
    vn: 'spread A',
  },

  spread: {
    en: 'spread',
    fr: 'spread',
    vn: 'spread',
  },
  interest_rate: {
    en: 'interest rate',
    fr: "taux d'intérêt",
    vn: 'lãi suất',
  },
  long_term_rate: {
    en: 'long-term rate',
    fr: 'taux long',
    vn: 'lãi suất dài hạn',
  },
  short_term_rate: {
    en: 'short-term rate',
    fr: 'taux court',
    vn: 'lãi suất ngắn hạn',
  },
  inflation: {
    en: 'inflation rate',
    fr: "taux d'inflation",
    vn: 'tỷ lệ lạm phát',
  },
  index: {
    en: 'index',
    fr: 'indice',
    vn: 'chỉ số',
  },
  stock_index: {
    en: 'stock index',
    fr: 'indice action',
    vn: 'chỉ số chứng khoán',
  },
  property_index: {
    en: 'property index',
    fr: 'indice immobilier',
    vn: 'chỉ số bất động sản',
  },
  dividend_yield: {
    en: 'dividend yield',
    fr: 'taux de dividende',
    vn: 'tỷ lệ cổ tức',
  },
  occup_rate: {
    en: "property's occupancy rate",
    fr: "taux d'occupation immobilier",
    vn: 'tỷ lệ lấp đầy bất động sản',
  },

  riskNeutral: {
    en: 'risk-neutral',
    fr: 'risque-neutre',
    vn: 'risk-neutral',
    to: '#/eco/risk-neutral-esg',
  },
  realWorld: {
    en: 'real-world',
    fr: 'monde-réel',
    vn: 'real-world',
    to: '#/eco/real-world-esg',
  },
  riskNeutralDet: {
    en: 'Risk-neutral (CE)',
    fr: 'Risque-neutre (CE)',
    vn: 'Certainty Equivalent',
  },
  balanceSheet: {
    en: 'balance sheet',
    fr: 'bilan',
    vn: 'bảng cân đối kế toán',
    to: '#/balance-sheet',
  },
  projection: {
    en: 'projection',
    fr: 'projection',
    vn: 'Mô phỏng',
  },
  projections: {
    en: 'projections',
    fr: 'projections',
    vn: 'Mô phỏng',
  },
  detProj: {
    en: 'deterministic projections',
    fr: 'projections déterministes',
    vn: 'Mô phỏng xác định',
  },
  RnStoProj: {
    en: 'risk-neutral stochastic projections',
    fr: 'projections stochastiques risque-neutre',
    vn: 'Mô phỏng ngẫu nhiên risk-neutral',
  },
  RwStoProj: {
    en: 'real-world stochastic projections',
    fr: 'projections stochastiques monde-réel',
    vn: 'Mô phỏng ngẫu nhiên real-world',
  },
  asset: { en: 'asset', fr: 'actif', vn: 'tài sản', to: '#/assets/portfolio' },
  assets: { en: 'assets', fr: 'actifs', vn: 'tài sản', to: '#/assets/portfolio' },
  assetClasses: {
    en: 'asset classes',
    fr: "classes d'actif",
    vn: 'nhóm tài sản',
    to: '#/assets/portfolio',
  },
  intang_assets: {
    en: 'Intangible assets',
    fr: 'Actifs incorporels',
    vn: 'Tài sản vô hình',
    to: '#/balance-sheet',
  },
  goodwill: { en: 'Goodwill', fr: 'Goodwill', vn: 'Goodwill', to: '#/balance-sheet' },
  other_intang_assets: {
    en: 'Other intangible assets',
    fr: 'Autres actifs incorporels',
    vn: 'Tài sản vô hình khác',
    to: '#/balance-sheet',
  },

  investments: {
    en: 'Investments (other than assets held for IL and UL contracts)',
    fr: "Investissements (autres qu'actifs en représentation de contrats en UC et indexés)",
    vn: 'Các khoản đầu tư (ngoại trừ tài sản nắm giữ theo hợp đồng IL và UL)',
    to: '#/assets/portfolio',
  },
  participation: {
    en: 'Holdings in related undertakings (including participations)',
    fr: 'Détentions dans des entreprises liées (y.c. Participations)',
    vn: 'Tải sản sở hữu trong các công ty liên quan',
    to: '#/assets/portfolio',
  },
  property: {
    en: 'Property',
    fr: 'Immobilier',
    vn: 'Bất động sản',
    to: '#/assets/portfolio',
  },
  equity: {
    en: 'equities',
    fr: 'actions',
    vn: 'cổ phiếu',
    to: '#/assets/portfolio',
  },
  listed_equity: {
    en: 'Listed Equities',
    fr: 'Actions cotées',
    vn: 'Cổ phiếu niêm yết',
    to: '#/assets/portfolio',
  },
  unlisted_equity: {
    en: 'Unlisted Equities',
    fr: 'Actions non-cotées',
    vn: 'Cổ phiếu không niêm yết',
    to: '#/assets/portfolio',
  },
  bond: {
    en: 'Bonds',
    fr: 'Obligations',
    vn: 'Trái phiếu',
    to: '#/assets/portfolio',
  },

  gov_bond: {
    en: 'Government Bonds',
    fr: "Obligations d'Etat",
    vn: 'Trái phiếu chính phủ',
    to: '#/assets/portfolio',
  },
  corp_bond: {
    en: 'Corporate Bonds',
    fr: "Obligations d'entreprises",
    vn: 'Trái phiếu doanh nghiệp',
    to: '#/assets/portfolio',
  },
  note: {
    en: 'Negotiable Notes/Papers',
    fr: 'Titres à Créances Négociables',
    vn: 'Notes',
    to: '#/assets/portfolio',
  },
  col_sec: {
    en: 'Collateralized securities',
    fr: 'Sûretés',
    vn: 'Tài sản thế chấp',
    to: '#/assets/portfolio',
  },
  ucits: {
    en: 'UCITS',
    fr: 'OPCVM',
    vn: 'Quỹ tương hỗ',
  },
  assets_ul: {
    en: 'Assets held for IL and UL contracts',
    fr: 'Actifs en représentation de contrats en UC et indexés',
    vn: 'Tài sản nắm giữ cho các hợp đồng liên kết đơn vị và liên kết chỉ mục',
  },
  loan: {
    en: 'Loans',
    fr: 'Prêts',
    vn: 'Hợp đồng cho vay',
  },
  receivables: {
    en: 'Receivables',
    fr: 'Créances',
    vn: 'Khoản phải thu',
  },

  other_assets: {
    en: 'Other assets',
    fr: 'Autres actifs',
    vn: 'Các tài sản khác',
  },
  materials: {
    en: 'Materials & Furniture',
    fr: 'Matériels & Mobiliers',
    vn: 'Thiết bị & Nội thất',
  },
  cash: {
    en: 'Cash',
    fr: 'Liquidité',
    vn: 'Tiền mặt',
  },
  cashflow: {
    en: 'cash flows',
    fr: 'flux',
    vn: 'dòng tiền',
  },

  // by category
  fixed_rate_bond: {
    en: 'Fixed Rate Bond',
    fr: 'Obligation à Taux Fixe',
    vn: 'Trái phiếu lãi suất cố định',
  },
  float_rate_bond: {
    en: 'Floating Rate Bond',
    fr: 'Obligation à Taux Variable',
    vn: 'Trái phiếu lãi suất thả nổi',
  },
  infl_index_bond: {
    en: 'Inflation Indexed Bond',
    fr: 'Obligation Indexée Inflation',
    vn: 'Trái phiếu lãi suất theo lạm phát',
  },
  fixed_rate_loan: {
    en: 'Fixed Rate Loan',
    fr: 'Prêt à Taux Fixe',
    vn: 'Khoản cho vay lãi suất cố định',
  },
  float_rate_loan: {
    en: 'Floating Rate Loan',
    fr: 'Prêt à Taux Variable',
    vn: 'Khoản cho vay lãi suất thả nổi',
  },

  trade: {
    en: 'Trades',
    fr: 'Achats/ventes',
    vn: 'Mua/bán',
  },
  buy: {
    en: 'Buy',
    fr: 'Achat',
    vn: 'Mua',
  },
  sell: {
    en: 'Sell',
    fr: 'Vente',
    vn: 'Bán',
  },
  forcing: {
    en: 'Forcing',
    fr: 'Forçage',
    vn: 'Điều chỉnh giá',
  },

  // asset fields
  currency: {
    en: 'currency',
    fr: 'devise',
    vn: 'tiền tệ',
  },
  cost_price: {
    en: 'Cost price',
    fr: 'Prix de revient (PdR)',
    vn: 'Giá mua',
  },
  book_value: {
    en: 'Book value',
    fr: 'Valeur nette comptable (VNC)',
    vn: 'Giá trị sổ sách ròng',
  },
  market_value: {
    en: 'Market value',
    fr: 'Valeur de marché (VM)',
    vn: 'Giá thị trường',
  },
  unrealized_pnl: {
    en: 'unrealized Profit and Loss (PnL)',
    fr: 'plus ou moins value-latente (PMVL)',
    vn: 'lãi/lỗ chưa thực hiện',
  },
  unrealized_pnl_rate: {
    en: 'unrealized Profit and Loss (PnL) rate',
    fr: 'taux de plus ou moins value-latente (PMVL)',
    vn: 'tỉ suất lãi/lỗ chưa thực hiện',
  },
  quantity: {
    en: 'Quantity',
    fr: 'Quantité',
    vn: 'Số lượng',
  },
  quote: {
    en: 'Quote',
    fr: 'Cours',
    vn: 'Tỉ giá',
  },
  nominal_yield: {
    en: 'Nominal yield',
    fr: 'Taux nominal',
    vn: 'Lãi suất danh nghĩa',
  },
  issuer: {
    en: 'issuer',
    fr: 'émetteur',
    vn: 'phát hành',
  },

  issue_date: {
    en: 'Issue date',
    fr: "Date d'émission",
    vn: 'ngày phát hành',
  },

  redemption_date: {
    en: 'Redemption date',
    fr: 'Date de remboursement',
    vn: 'Ngày đáo hạn',
  },
  redemption_mode: {
    en: 'Redemption mode',
    fr: 'Type de remboursement',
    vn: 'Cách trả gốc',
  },
  rating: {
    en: 'Rating',
    fr: 'Notation',
    vn: 'Xếp hạng',
  },
  freq: {
    en: 'Payment frequency',
    fr: 'Fréquence de paiement',
    vn: 'Tần suất thanh toán',
  },
  premium: {
    en: 'Premium/discount',
    fr: 'Surcote/décote',
    vn: 'Premium/discount',
  },
  nominal: {
    en: 'Nominal value',
    fr: 'Nominal',
    vn: 'Giá danh nghĩa',
  },
  indexed_nominal: {
    en: 'indexed nominal',
    fr: 'Nominal indexé',
    vn: 'Giá danh nghĩa điều chỉnh theo lạm phát',
  },
  redemption: {
    en: 'Redemption value',
    fr: 'Valeur de remboursement',
    vn: '',
  },
  YTM_cost_price: {
    en: 'yield to maturity',
    fr: "taux actuariel à l'achat",
    vn: 'lợi suất khi đáo hạn',
  },
  current_yield: {
    en: 'current_yield',
    fr: 'taux actuariel (valeur de marché)',
    vn: 'lợi suất hiện hành',
  },
  beta: {
    en: 'beta',
    fr: 'bêta',
    vn: 'bê-ta',
  },
  pdd: {
    en: 'Provision for Impairment',
    fr: 'Provision pour Dépréciation Durable (PDD)',
    vn: 'Dự phòng suy giảm',
  },
  running_coupon: {
    en: 'running coupon',
    fr: 'coupon couru (CC)',
    vn: 'lãi suất chạy',
  },
  recognized_revenue: {
    en: 'recognized revenue',
    fr: 'revenu comptable',
    vn: 'doanh thu ghi nhận',
  },
  received_revenue: {
    en: 'received revenue',
    fr: 'revenu encaissé',
    vn: 'doanh thu nhận được',
  },
  indexed_revenue: {
    en: 'indexed revenue',
    fr: 'revenu indexé',
    vn: 'doanh thu nhờ lạm phát',
  },
  realized_pnl: {
    en: 'realized PnL',
    fr: 'plus ou moins-values rélalisées (PMVR)',
    vn: 'lãi lỗ thực hiện',
  },
  fees: {
    en: 'financial fees',
    fr: 'frais financiers',
    vn: 'phí tài chính',
  },
  var_premium: {
    en: 'premium/discount variation',
    fr: 'variation de surcote/décote',
    vn: 'biến động premium/discount',
  },
  premium_sell_buy: {
    en: 'premium/discount from trading',
    fr: 'surcote/décote ventes/achats',
    vn: 'premium/disount do mua/bán',
  },
  var_running_coupon: {
    en: 'running coupon variation',
    fr: 'variation de coupons courus',
    vn: 'biến động lãi suất chạy',
  },
  running_coupon_sell_buy: {
    en: 'running coupon from trading',
    fr: 'coupons courus ventes/achats',
    vn: 'lãi suất chạy do mua/bán',
  },
  var_pdd: {
    en: 'Provision for Impairment Variation',
    fr: 'Variation de PDD',
    vn: 'biến động dự phòng suy giảm',
  },
  var_res_capi: {
    en: 'Capitalization Reserve Variation',
    fr: 'mouvement de Réserve de Capitalisation',
    vn: 'biến động Capitalization Reserve',
  },
  non_tech_var_res_capi: {
    en: 'Capitalization Reserve Non Technical Variation',
    fr: 'mouvement non technique de Réserve de Capitalisation',
    vn: 'biến động phi kỹ thuật Capitalization Reserve',
  },
  var_amort: {
    en: 'amortization',
    fr: 'amortissement',
    vn: 'khấu hao',
  },
  amort: {
    en: 'amortization (cumulative)',
    fr: 'amortissement (cumulé)',
    vn: 'khấu hao cộng dồn',
  },
  rent: {
    en: 'rent',
    fr: 'loyer',
    vn: 'tiền cho thuê',
  },
  charges: {
    en: 'charges',
    fr: 'charges',
    vn: 'chi phí',
  },
  // liabibility vocab
  liabilities: { en: 'Liabilities', fr: 'Passifs', vn: 'Nợ' },
  own_funds: {
    en: 'Own funds',
    fr: 'Fonds propres & Réserves',
    vn: 'Vốn chủ sở hữu và dự trữ',
  },
  capital: {
    en: 'Capital',
    fr: 'Capitaux Propres',
    vn: 'Vốn chủ sở hữu',
  },
  ret_earn: {
    en: 'Retained earnings',
    fr: 'Report à Nouveau',
    vn: 'Lợi nhuận giữ lại',
  },
  last_earn: {
    en: 'Last Fiscal Year (LFY) earning',
    fr: 'Résultat du dernier exercice',
    vn: 'Thu nhập năm tài chính gần nhất',
  },
  res_capi: {
    en: 'Capitalization reserve',
    fr: 'Réserve de Capitalisation',
    vn: 'Capitalization reserve',
  },
  sub_debts: {
    en: 'Subordinated Debts',
    fr: 'Dettes subordonnées',
    vn: 'Nợ thứ cấp',
  },
  sub_debt_charges: {
    en: 'Subordinated Debt Charges',
    fr: 'Charges sur Dettes subordonnées',
    vn: 'Nợ thứ cấp',
  },
  tech_prov: {
    en: 'Technical Provisions',
    fr: 'Provisions Techniques',
    vn: 'Dự phòng kỹ thuật',
  },
  ppb: {
    en: 'Profit Sharing Reserve (PSR)',
    fr: 'Provision pour Participations aux Bénéfices (PPB)',
    vn: 'Dự trữ chia sẻ lợi nhuận',
  },
  var_ppb: {
    en: 'Profit Sharing Reserve Variation',
    fr: 'variation de PPB',
    vn: 'Biến động dự trữ chia sẻ lợi nhuận',
  },
  pre: {
    en: 'Liquidity Risk Reserve (LRR)',
    fr: "Provision pour Risques d'Exgibilité (PRE)",
    vn: 'Dự phòng rủi ro thanh khoản',
  },
  pm_no_ul: {
    en: 'Mathematical Provisions (other than ones for IL and UL contracts)',
    fr: 'Provisions Mathématiques (hors UC)',
    vn: 'Dự phòng toán học (ngoại trừ dự phòng hợp đồng IL và UL)',
  },
  pm_ps: {
    en: 'Mathematical Provisions for Profit Sharing Contracts',
    fr: 'PM Eparne Euro',
    vn: 'Dự phòng toán học cho hợp đồng liên kết chung',
  },
  pm_wl: {
    en: 'Mathematical Provisions for Whole Life Contracts',
    fr: 'PM Vie Entière',
    vn: 'Dự phòng toán học cho hợp đồng bảo hiểm trọn đời',
  },
  pm_others: {
    en: 'Mathematical Provisions for other contracts (pension, health, ...)',
    fr: 'PM Prévoyance',
    vn: 'Dự phòng toán học cho các loại hợp đồng khác (bảo hiểm hưu trí, sức khoẻ, ...)',
  },
  pm_ul: {
    en: 'Mathematical Provisions for IL and UL contracts',
    fr: 'Provisions Mathématiques UC',
    vn: 'Dự phòng toán học cho hợp đồng IL và UL',
  },
  prc: {
    en: 'Contingency and Loss Reserve',
    fr: 'Provision pour Risques et Charges (PRC)',
    vn: 'Dự phòng tổn thất',
  },
  debts: {
    en: 'Non-subordinated debts',
    fr: 'Dettes non subordonnées',
    vn: 'Nợ',
  },
  other_debts: {
    en: 'Other Debts',
    fr: 'Dettes diverses',
    vn: 'Các khoản nợ khác',
  },
  fiscal_debts: {
    en: 'Fiscal Debts',
    fr: 'Dettes fiscales',
    vn: 'Nợ thuế',
  },
  short_term_debts: {
    en: 'Short-term Debts',
    fr: 'Dettes court termes',
    vn: 'Nợ ngắn hạn',
  },

  // liab vocab
  pm_flat: {
    en: 'Mathematical Provisions (flat)',
    fr: 'PM sans revalo',
    vn: 'PM sans revalo',
  },
  pm_tmg: {
    en: 'Mathematical Provisions (guaranteed)',
    fr: 'PM garantie (TMG)',
    vn: 'PM garantie (TMG)',
  },
  pm: {
    en: 'Mathematical Provisions',
    fr: 'PM',
    vn: 'PM',
  },
  prim: {
    en: 'premiums',
    fr: 'primes',
    vn: 'primes',
  },
  new_b: {
    en: 'new business',
    fr: 'affaires nouvelles',
    vn: 'affaires nouvelles',
  },
  vers: {
    en: 'stock premiums',
    fr: 'versements',
    vn: 'versements',
  },
  rach: {
    en: 'lapses',
    fr: 'rachats',
    vn: 'lapses',
  },
  rach_struc: {
    en: 'baseline lapses',
    fr: 'rachats structurels',
    vn: 'baseline lapses',
  },
  rach_conj: {
    en: 'dynamic lapses',
    fr: 'rachats conjoncturels',
    vn: 'dynamic lapses',
  },
  dec: {
    en: 'death benefits',
    fr: 'décès',
    vn: 'death benefits',
  },
  ech: {
    en: 'maturities',
    fr: 'échéances',
    vn: 'maturities',
  },
  presta: {
    en: 'claims',
    fr: 'prestations',
    vn: 'claims',
  },
  chgt_enc: {
    en: 'outstanding loads',
    fr: 'chargements sur encours',
    vn: 'outstanding loads',
  },
  chgt_prim: {
    en: 'premium loads',
    fr: 'chargements sur primes',
    vn: 'premium loads',
  },
  pen_rach: {
    en: 'lapse penalties',
    fr: 'pénatlités de rachats',
    vn: 'lapse penalties',
  },
  csg: {
    en: 'Generalized Social Contribution',
    fr: 'Contribution Sociale Généralisée (CSG)',
    vn: 'Generalized Social Contribution',
  },
  ic_pb: {
    en: 'participations',
    fr: 'intérêts crédités et participations aux bénéfices',
    vn: 'participations',
  },
  ic: {
    en: 'credited interests',
    fr: 'intérêts crédités',
    vn: 'credited interests',
  },
  ic_pm: {
    en: 'credited interests on MP',
    fr: 'IC sur PM',
    vn: 'credited interests on MP',
  },
  ic_liq_presta: {
    en: 'credited interests on claims',
    fr: 'IC sur prestations',
    vn: 'credited interests on claims',
  },
  ic_liq_rach: {
    en: 'credited interests on surrender values',
    fr: 'IC sur rachats',
    vn: 'credited interests on surrender values',
  },
  ic_liq_dec: {
    en: 'credited interests on death benefits',
    fr: 'IC sur décès',
    vn: 'credited interests on death benefits',
  },
  ic_liq_ech: {
    en: 'credited interests on maturities',
    fr: 'IC sur échéances',
    vn: 'credited interests on maturities',
  },
  pb: {
    en: 'profit sharing',
    fr: 'participations aux bénéfices',
    vn: 'profit sharing',
  },
  pb_pm: {
    en: 'profit sharing on MP',
    fr: 'PB sur PM',
    vn: 'profit sharing on MP',
  },
  pb_liq_presta: {
    en: 'profit sharing on claims',
    fr: 'PB sur prestations',
    vn: 'profit sharing on claims',
  },
  pb_liq_rach: {
    en: 'profit sharing on surrender values',
    fr: 'PB sur rachats',
    vn: 'profit sharing on surrender values',
  },
  pb_liq_dec: {
    en: 'profit sharing on death benefits',
    fr: 'PB sur décès',
    vn: 'profit sharing on death benefits',
  },
  pb_liq_ech: {
    en: 'profit sharing on maturities',
    fr: 'PB sur échéances',
    vn: 'profit sharing on maturities',
  },

  frais_enc: {
    en: 'outstanding expenses',
    fr: 'frais sur encours',
    vn: 'outstanding expenses',
  },
  frais_dec: {
    en: 'death expenses',
    fr: 'frais sur décès',
    vn: 'death expenses',
  },
  frais_rach: {
    en: 'surrender expenses',
    fr: 'frais sur rachats',
    vn: 'surrender expenses',
  },
  frais_ech: {
    en: 'maturity expenses',
    fr: 'frais sur échéances',
    vn: 'maturity expenses',
  },
  frais_presta: {
    en: 'claim expenses',
    fr: 'frais sur prestations',
    vn: 'claim expenses',
  },
  frais_new_b: {
    en: 'new business expenses',
    fr: 'frais sur affaires nouvelles',
    vn: 'new business expenses',
  },
  frais_vers: {
    en: 'premium (stock) expenses',
    fr: 'frais sur versements',
    vn: 'premium (stock) expenses',
  },
  frais_prim: {
    en: 'premium expenses (NB + stock)',
    fr: 'frais sur primes (AN + VL)',
    vn: 'premium expenses (NB + stock)',
  },
  frais_admin: {
    en: 'administrative expenses',
    fr: 'frais administratifs',
    vn: 'administrative expenses',
  },
  frais_autres: {
    en: 'other expenses',
    fr: 'autres frais',
    vn: 'other expenses',
  },
  frais_assu: {
    en: 'insurance expenses',
    fr: 'frais assurance',
    vn: 'insurance expenses',
  },

  comm_enc: {
    en: 'outstanding commissions',
    fr: 'commissions sur encours',
    vn: 'outstanding commissions',
  },

  comm_prim: {
    en: 'premium commissions (NB + stock)',
    fr: 'commissions sur primes (AN + VL)',
    vn: 'premium commissions (NB + stock)',
  },
  comm_assu: {
    en: 'insurance commissions',
    fr: 'commissions assurance',
    vn: 'insurance commissions',
  },
  frais_comm_assu: {
    en: 'insurance expenses & commissions',
    fr: 'frais & commissions assurance',
    vn: 'insurance expenses & commissions',
  },

  assu_in: {
    en: 'insurance inflows',
    fr: 'CF assurance In',
    vn: 'insurance inflows',
  },
  assu_out: {
    en: 'insurance outflows',
    fr: 'CF assurance Out',
    vn: 'insurance outflows',
  },
  assu_net: {
    en: 'insurance net flows',
    fr: 'CF assurance Net',
    vn: 'insurance net flows',
  },

  income_statement: {
    en: 'income statement',
    fr: 'compte de résultats',
    vn: 'báo cáo thu nhập',
  },

  res_tech: {
    en: 'technical income',
    fr: 'résultat technique',
    vn: 'doanh thu thuần bảo hiểm',
  },
  tech_prod: {
    en: 'technical income on production',
    fr: 'résultat technique sur production',
    vn: 'doanh thu thuần bán bảo hiểm',
  },
  tech_enc: {
    en: 'technical income on oustanding',
    fr: 'résultat technique sur encours',
    vn: 'doanh thu thuần quản lý hợp đồng bảo hiểm',
  },
  charges_autres: {
    en: 'other charges',
    fr: 'autres charges',
    vn: 'chi phí khác',
  },
  produits_autres: {
    en: 'other incomes',
    fr: 'autres produits',
    vn: 'doanh thu khác',
  },

  charg_prov_hors_pre: {
    en: 'provision charges (w/o LRR)',
    fr: 'charges de provisions hors PRE',
    vn: 'provision charges (w/o LRR)',
  },
  ajust_uc: {
    en: 'ACAV adjustments',
    fr: 'ajustements ACAV',
    vn: 'ACAV adjustments',
  },
  var_pm: {
    en: 'MP variation',
    fr: 'variation de PM',
    vn: 'MP variation',
  },
  var_prov_tech_div: {
    en: 'other technical provision variation',
    fr: 'variation de provisions techniques diverses',
    vn: 'other technical provision variation',
  },
  var_ecart_zil: {
    en: 'zillmerisation variation',
    fr: "variation de l'écart de zillmérisation",
    vn: 'zillmerisation variation',
  },
  var_far: {
    en: 'deferred acquisition cost variation',
    fr: "variation de Frais d'acquisition reportés",
    vn: 'deferred acquisition cost variation',
  },
  revalo_arr_cap_dec: {
    en: 'costs of revaluation of arrears and death benefits',
    fr: 'coûts de revalorisation des arrérages et capitaux décès',
    vn: 'chi phí đánh giá lại khoản nợ đọng và quyền lợi tử tuất',
  },
  chgt_fond_revalo_div: {
    en: 'loadings on revaluation funds and miscellaneous',
    fr: 'chargements prélevés sur fonds de revalorisation et divers',
    vn: 'loadings on revaluation funds and miscellaneous',
  },
  var_reval_fund_div: {
    en: 'revaluation funds variation',
    fr: 'variation de fonds de revalorisation',
    vn: 'biến động quỹ định giá',
  },
  var_guar_fund_div: {
    en: 'guaranteed funds and diverse non technical reserve variation',
    fr: 'variation de fonds de garantie et de provisions techniques diverses',
    vn: 'biến động quỹ đảm bảo và các dự phòng khác',
  },

  tmg: {
    en: 'MGR',
    fr: 'TMG',
    vn: 'MGR',
  },
  ts: {
    en: 'participation rate',
    fr: 'taux servi',
    vn: 'participation rate',
  },
  ts_prov: {
    en: 'temporary rate',
    fr: 'taux servi provisoire',
    vn: 'temporary rate',
  },
  ts_def: {
    en: 'definitive rate',
    fr: 'taux servi définitif',
    vn: 'definitive rate',
  },
  tx_attendu: {
    en: 'expected rate',
    fr: 'taux attendu',
    vn: 'expected rate',
  },
  tx_cible: {
    en: 'target rate',
    fr: 'taux cible',
    vn: 'target rate',
  },
  insured_gap: {
    en: 'participation gap',
    fr: 'écart servi-attendu',
    vn: 'participation gap',
  },
  tx_rach_conj: {
    en: 'dynamic surrender rate',
    fr: 'taux de rachats conjoncturels',
    vn: 'dynamic surrender rate',
  },
}

export const siiDict = {
  ...techDict,
  agGrid: { en: null, fr: AG_GRID_LOCALE_FR, vn: AG_GRID_LOCALE_VN },
  fr: { en: 'french', fr: 'français', vn: 'tiếng pháp' },
  en: { en: 'english', fr: 'anglais', vn: 'tiếng anh' },
  vn: { en: 'vietnamese', fr: 'vietnamien', vn: 'tiếng việt' },
  message: { en: 'message', fr: 'message', vn: 'tin nhắn' },
  comingSoon: { en: 'Coming soon...', fr: 'Bientôt disponible...', vn: 'Sắp ra mắt...' },
  unavailable: { en: 'unavailable', fr: 'indisponible', vn: 'không khả dụng' },
  level: {
    en: 'level',
    fr: 'niveau',
    vn: 'mức độ',
  },
  granularity: {
    en: 'granularity',
    fr: 'granularité',
    vn: 'độ chi tiết',
  },
  per: { en: 'by', fr: 'par', vn: 'theo' },
  with: {
    en: 'with',
    fr: 'avec',
    vn: 'với',
  },
  without: {
    en: 'without',
    fr: 'sans',
    vn: 'không',
  },
  none: {
    en: 'none',
    fr: 'aucun(e)',
    vn: 'không',
  },
  from: { en: 'from', fr: 'à partir de', vn: 'từ' },
  to: { en: 'to', fr: 'à', vn: 'đến' },
  until: { en: 'until', fr: "jusqu'à", vn: 'cho đến' },
  for: { en: 'for', fr: 'pour', vn: 'cho' },
  of: { en: 'of', fr: 'de', vn: 'của' },
  and: { en: 'and', fr: 'et', vn: 'và' },
  or: { en: 'or', fr: 'ou', vn: 'hoặc' },
  yes: { en: 'yes', fr: 'oui', vn: 'có' },
  no: { en: 'no', fr: 'non', vn: 'không' },
  guest: { en: 'guest account', fr: 'compte invité', vn: 'tài khoản khách mời' },
  summary: { en: 'Summary', fr: 'Récapitulatif', vn: 'Tóm tắt' },
  table: { en: 'table', fr: 'table', vn: 'bảng dữ liệu' },
  theme: { en: 'Theme', fr: 'Thème', vn: 'Chủ đề' },
  height: { en: 'Height', fr: 'Hauteur', vn: 'Độ cao' },
  guide: { en: 'Guide', fr: 'Guide', vn: 'Hướng dẫn' },
  name: { en: 'name', fr: 'nom', vn: 'tên' },
  unknownName: { en: 'unknown name', fr: 'nom inconnu', vn: 'tên không xác định' },
  class: { en: 'class', fr: 'classe', vn: 'nhóm' },
  siiCodes: { en: 'Solvencii codes', fr: 'codes Solvencii', vn: 'Mã Solvencii' },
  siiLabels: { en: 'Solvencii labels', fr: 'libellés Solvencii', vn: 'Tên Solvencii' },
  dict: { en: 'dictionary', fr: 'dictionnaire', vn: 'từ điển' },
  translatedInto: { en: 'translated into', fr: 'traduit en', vn: 'dịch ra' },

  relative: { en: 'relative', fr: 'relatif', vn: 'tương đối' },
  absolute: { en: 'absolute', fr: 'absolu', vn: 'tuyệt đối' },
  relative_shock: { en: 'relative shock', fr: 'choc relatif', vn: 'sốc tương đối' },
  absolute_shock: { en: 'absolute shock', fr: 'choc absolu', vn: 'sốc tuyệt đối' },
  per_maturity_shock: { en: 'per maturity shock', fr: 'choc par maturité', vn: 'sốc theo kỳ hạn' },

  number: { en: 'number', fr: 'nombre', vn: 'số' },
  unknownNumber: { en: 'unknown number', fr: 'nombre inconnu', vn: 'số không xác định' },
  negativeNumber: { en: 'non-positive number', fr: 'nombre négatif ou nul', vn: 'số không dương' },
  other: { en: 'Other', fr: 'Autre', vn: 'Khác' },
  others: { en: 'Others', fr: 'Autres', vn: 'Khác' },
  errors: { en: 'error(s)', fr: 'erreur(s)', vn: 'lỗi' },
  detected: { en: 'detected', fr: 'détecté(es)', vn: 'được phát hiện' },
  description: { en: 'Description', fr: 'Description', vn: 'Mô tả' },
  annual: {
    en: 'annual',
    fr: 'annuel',
    vn: 'theo năm',
  },
  monthly: {
    en: 'monthly',
    fr: 'mensuel',
    vn: 'theo tháng',
  },
  quarterly: {
    en: 'quarterly',
    fr: 'trimestriel',
    vn: 'theo quý',
  },
  asOf: { en: 'as of', fr: 'au', vn: 'tại' },
  details: { en: 'details', fr: 'détails', vn: 'chi tiết' },
  input: { en: 'input', fr: 'input', vn: 'dữ liệu đầu vào' },
  output: { en: 'output', fr: 'output', vn: 'dữ liệu đầu ra' },
  history: { en: 'historical data', fr: 'historique', vn: 'dữ liệu quá khứ' },
  month: { en: 'month', fr: 'mois', vn: 'tháng' },
  months: { en: 'months', fr: 'mois', vn: 'tháng' },
  year: { en: 'year', fr: 'année', vn: 'năm' },
  years: { en: 'years', fr: 'années', vn: 'năm' },
  yearByYear: { en: 'year by year', fr: 'année par année', vn: 'theo từng năm' },
  monthByMonth: { en: 'month by month', fr: 'mois par mois', vn: 'theo từng tháng' },
  duree: { en: 'length', fr: 'durée', vn: 'thời gian' },
  upTo: { en: 'up to', fr: "jusqu'à", vn: 'cho tới' },

  // actions
  load: { en: 'Load', fr: 'Charger', vn: 'Tải' },
  loaded: { en: 'loaded', fr: 'chargé', vn: 'đã tải' },
  proj: { en: 'Project', fr: 'Projeter', vn: 'Mô phỏng' },
  find: { en: 'Find', fr: 'Recherche', vn: 'Tìm kiếm' },
  open: { en: 'Open', fr: 'Ouvrir', vn: 'Mở' },
  close: { en: 'Close', fr: 'Fermer', vn: 'Đóng' },
  run: { en: 'Run', fr: 'Exécuter', vn: 'Chạy' },
  shock: { en: 'Shock', fr: 'Choquer', vn: 'Sốc' },
  shocked: { en: 'shocked', fr: 'choqué(e)', vn: 'sốc' },
  shocks: { en: 'shocks', fr: 'chocs', vn: 'sốc' },
  import: { en: 'Import', fr: 'Importer', vn: 'Tải lên' },
  export: { en: 'Export', fr: 'Exporter', vn: 'Tải xuống' },
  inspect: { en: 'Inspect', fr: 'Inspecter', vn: 'Xem' },
  create: { en: 'Create', fr: 'Créer', vn: 'Tạo mới' },
  created: { en: ' has been created', fr: ' a été créé(e)', vn: ' đã được tạo' },
  modify: { en: ' Modify', fr: 'Modifier', vn: 'Thay đổi' },
  modified: { en: ' has been modified', fr: ' a été modifié(e)', vn: ' đã được thay đổi' },
  duplicate: { en: 'Duplicate', fr: 'Dupliquer', vn: 'Sao chép' },
  duplicated: { en: ' has been duplicated', fr: ' a été dupliqué(e)', vn: ' đã được sao chép' },
  duplicatedN: {
    en: ' have been duplicated',
    fr: ' ont été dupliqué(e)s',
    vn: ' đã được sao chép',
  },
  delete: { en: 'Delete', fr: 'Supprimer', vn: 'Xóa' },
  deleted: { en: ' has been deleted', fr: ' a été supprimé(e)', vn: ' đã được xóa' },
  deletedN: { en: ' have been deleted', fr: ' ont été supprimé(e)s', vn: ' đã được xóa' },
  projected: { en: ' has been projected', fr: ' a été projeté(e)', vn: ' đã được dự phóng' },
  projectedN: { en: ' have been projected', fr: ' ont été projeté(e)s', vn: ' đã được dự phóng' },
  compare: { en: 'Compare', fr: 'Comparer', vn: 'So sánh' },
  cancel: { en: 'Cancel', fr: 'Annuler', vn: 'Huỷ' },
  submit: { en: 'Submit', fr: 'Valider', vn: 'Xác nhận' },
  save: { en: 'Save', fr: 'Sauvegarder', vn: 'Lưu' },
  saved: { en: 'saved', fr: 'sauvegardé(e)', vn: 'đã được lưu' },
  reset: { en: 'Reset', fr: 'Réinitialiser', vn: 'Khôi phục mặc định' },
  add: { en: 'Add', fr: 'Ajouter', vn: 'Thêm vào' },
  added: { en: 'added', fr: 'ajouté(e)', vn: 'đã được thêm vào' },
  actionCompleted: {
    en: 'Action completed successfully',
    fr: 'Action réalisée avec succès',
    vn: 'Tác vụ đã hoàn thành',
  },
  select: { en: 'select', fr: 'choisir', vn: 'chọn' },
  notSelected: {
    en: ' not selected',
    fr: ' non selectionné(e)',
    vn: ' chưa được chọn',
  },
  selected: {
    en: ' has been selected',
    fr: ' a été selectionné(e)',
    vn: ' đã được chọn',
  },
  selectedN: {
    en: ' have been selected',
    fr: ' ont été selectionné(e)s',
    vn: ' đã được chọn',
  },
  management: { en: 'management', fr: 'gestion', vn: 'quản lý' },
  controls: { en: 'controls', fr: 'contrôles', vn: 'kiểm tra' },
  folder: { en: 'folder', fr: 'dossier', vn: 'thư mục' },
  file: { en: 'file', fr: 'fichier', vn: 'tệp tin' },
  files: { en: 'files', fr: 'fichiers', vn: 'tệp tin' },
  format: { en: 'format', fr: 'format', vn: 'định dạng' },
  size: { en: 'size', fr: 'taille', vn: 'kích thước' },
  chart: { en: 'chart', fr: 'graph', vn: 'biểu đồ' },
  barChart: { en: 'bar chart', fr: 'histogramme', vn: 'biểu đồ cột' },
  lineChart: { en: 'line chart', fr: 'courbe', vn: 'biểu đồ đường' },
  pointChart: { en: 'dots', fr: 'nuage de points', vn: 'biểu đồ điểm' },
  label: { en: 'label', fr: 'libellé', vn: 'tên' },
  show: { en: 'show', fr: 'afficher', vn: 'hiển thị' },
  hide: { en: 'hide', fr: 'masquer', vn: 'ẩn' },
  more: { en: 'more', fr: 'plus', vn: 'thêm' },
  less: { en: 'less', fr: 'moins', vn: 'ít hơn' },
  total: { en: 'total', fr: 'total', vn: 'tổng cộng' },

  data: { en: 'data', fr: 'données', vn: 'thông tin' },
  graph: { en: 'Graphs', fr: 'Graphs', vn: 'Đồ thị' },
  tree: { en: 'tree', fr: 'arbre', vn: 'cây' },
  date: { en: 'date', fr: 'date', vn: 'ngày' },
  referenceDate: { en: 'reference date', fr: 'date de référence', vn: 'ngày tham chiếu' },
  riskNeutralDate: {
    en: 'risk-neutral date',
    fr: 'date de risque neutralisation',
    vn: 'ngày risk-neutral',
  },
  endDate: {
    en: 'en-of-projection date',
    fr: 'date de fin de projection',
    vn: 'ngày kết thúc mô phỏng',
  },
  before: { en: 'prior to', fr: 'antérieure à', vn: 'sớm hơn' },
  after: { en: 'after', fr: 'postérieure à', vn: 'muộn hơn' },
  imported: { en: 'imported', fr: 'importé(es)', vn: 'được tải lên' },
  not: { en: 'not', fr: 'non', vn: 'không' },

  balanceSheet0: {
    en: 'initial balance sheet',
    fr: 'bilan initial',
    vn: 'bảng cân đối kế toán ban đầu',
  },

  page: { en: 'page', fr: 'page', vn: 'trang' },

  home: { en: 'home page', fr: "page d'accueil", vn: 'trang chủ' },
  toolbox: { en: 'toolboX', fr: 'boîte à outils', vn: 'hộp công cụ' },

  copyright: { en: 'copyright', fr: "droits d'auteur", vn: 'bản quyền' },
  allRightsReserved: {
    en: 'all rights reserved',
    fr: 'Tous droits réservés',
    vn: 'đã đăng ký bản quyền',
  },

  pythonLoaded: {
    en: 'Python successfully loaded',
    fr: 'Python chargé avec succès',
    vn: 'Python đã được tải',
  },
  pythonMessage: {
    en: 'You can now write & run Python code directly in your web browser!',
    fr: 'Vous pouvez désormais écrire et exécuter les codes Python directement sur votre navigateur web !',
    vn: 'Bây giờ bạn có thể viết và chạy mã Python trực tiếp trên trình duyệt web của mình!',
  },
  pythonLoading: {
    en: 'Python still loading ...',
    fr: 'Python en cours de chargement ...',
    vn: 'Python đang được tải ...',
  },
  pythonWaitMessage: {
    en: 'Please wait for our Python code to be loaded. If Python is not ready in 30s, refresh the webpage!',
    fr: "Veuillez attendre que nos codes Python soient d'abord chargés. Si Python n'est pas prêt dans 30 secondes, actualisez la page Web !",
    vn: 'Vui lòng thử lại sau khi Python đã sẵn sàng. Nếu Python không sẵn sàng sau 30 giây nữa, vui lòng tải lại trang!',
  },

  pythonShow: {
    en: 'Switch to Python console',
    fr: 'Passer à la console Python',
    vn: 'Chuyển sang Python',
  },
  spreadsheetShow: {
    en: 'Switch to spreadsheet',
    fr: 'Passer au Tableur',
    vn: 'Chuyển sang bảng tính',
  },
  gridShow: {
    en: 'Switch to data table',
    fr: 'Passer à Table de données',
    vn: 'Chuyển sang bảng dữ liệu',
  },

  duplication: {
    en: 'duplicate',
    fr: 'doublon',
    vn: 'trùng lặp',
  },
  invalidValue: {
    en: 'Invalid value',
    fr: 'Valeur non valide',
    vn: 'Giá trị không hợp lệ',
  },
  nameTaken: {
    en: 'Name already taken',
    fr: 'Nom déjà utilisé',
    vn: 'Tên đã được sử dụng',
  },
  sameNameExisted: {
    en: "with the same name's already existed",
    fr: 'avec le même nom existe déjà',
    vn: 'cùng tên đã tồn tại',
  },
  renameMessage: {
    en: 'Please choose another name',
    fr: 'Veuillez utiliser un autre nom',
    vn: 'Vui lòng sử dụng tên khác',
  },
  startRow: {
    en: 'Data (header included) start at row n°',
    fr: "Données (avec l'en-tête) à partir de ligne",
    vn: 'Dữ liệu (bao gồm cả tiêu đề cột) bắt đầu từ dòng thứ',
  },

  // Excel
  spreadsheet: {
    en: 'spreadsheet',
    fr: 'tableur',
    vn: 'bảng tính',
  },
  shortcuts: {
    en: 'Keyboard shortcuts',
    fr: 'Raccourcis clavier',
    vn: 'Các phím tắt bàn phím',
  },
  shortcut: {
    en: 'Shortcut',
    fr: 'Raccourci',
    vn: 'Phím tắt',
  },
  feature: {
    en: 'Feature',
    fr: 'Fonction',
    vn: 'Tính năng',
  },

  category: { en: 'category', fr: 'catégorie', vn: 'mục' },
  cat: { en: 'category', fr: 'catégorie', vn: 'mục' },
  sub_cat: { en: 'sub-category', fr: 'sous-catégorie', vn: 'tiểu mục' },
  section: { en: 'fund', fr: 'section', vn: 'quỹ' },
  all_categories: { en: 'All categories', fr: 'Toutes catégories', vn: 'Tất cả các loại' },
  all_sections: { en: 'All funds', fr: 'Toutes sections', vn: 'Tất cả các quỹ' },
  all_classes: { en: 'All classes', fr: 'Toutes classes', vn: 'Tất cả các nhóm tài sản' },
  // asset vocab
  code_val: { en: 'value code', fr: 'code valeur', vn: 'mã tài sản' },
}

const initialState = objFromArrays(
  Object.keys(siiDict),
  Object.values(siiDict).map((e) => e['fr']),
)

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setLang, (state, action) => {
      const lang = action.payload
      const newState = objFromArrays(
        Object.keys(siiDict),
        Object.values(siiDict).map((e) => e[lang]),
      )
      return newState
    })
  },
})

export const getLabels = (state) => state.labels

export default labelsSlice.reducer
