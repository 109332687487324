import { createSlice } from '@reduxjs/toolkit'
const initialState = {}
export const outputDetSlice = createSlice({
  name: 'outputDetSlice',
  initialState,
  reducers: {
    update: (state, action) => (state = action.payload),
  },
})

export const { update } = outputDetSlice.actions
export const getOutputDet = (state) => state.outputDet // in store

export default outputDetSlice.reducer
