import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: 'default',
  id: 'default',
  initDate: '2023-12-31',
  sections: ['canton general'],
  fiscal: [{ year: 1, is: 0.25825, csg: 0.172 }],
  fin_fees: [
    {
      cat: 'fixed_rate_bond',
      sub_cat: 'corporate',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'fixed_rate_bond',
      sub_cat: 'government',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'float_rate_bond',
      sub_cat: 'corporate',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'float_rate_bond',
      sub_cat: 'government',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'infl_index_bond',
      sub_cat: 'corporate',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'infl_index_bond',
      sub_cat: 'government',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'note',
      sub_cat: 'corporate',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0.0007,
    },
    {
      cat: 'note',
      sub_cat: 'government',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'equity',
      sub_cat: 'listed',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'equity',
      sub_cat: 'unlisted',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'equity',
      sub_cat: 'participation',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    /*     {
      cat: 'property',
      sub_cat: 'residential',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'property',
      sub_cat: 'commercial',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    }, */

    {
      cat: 'fixed_rate_loan',
      sub_cat: 'covered',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'fixed_rate_loan',
      sub_cat: 'uncovered',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'float_rate_loan',
      sub_cat: 'covered',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'float_rate_loan',
      sub_cat: 'uncovered',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
    {
      cat: 'ucits',
      sub_cat: 'ucits',
      mana: 0,
      direct: 0,
      buy: 0,
      sell: 0,
      sellBuy: 0,
    },
  ],
  spreads: [
    { rating: 'AAA', coef: 0, rec_rate: 1 },
    { rating: 'AA', coef: 0.5, rec_rate: 1 },
    { rating: 'A', coef: 0, rec_rate: 1 },
    { rating: 'BBB', coef: 0, rec_rate: 1 },
    { rating: 'BB', coef: 0, rec_rate: 1 },
    { rating: 'B', coef: 0, rec_rate: 1 },
    { rating: 'CCC', coef: 0, rec_rate: 1 },
    { rating: 'CC', coef: 0, rec_rate: 1 },
    { rating: 'C', coef: 0, rec_rate: 1 },
    { rating: 'HY', coef: 0, rec_rate: 1 },
    { rating: 'NR', coef: 0, rec_rate: 1 },
  ],
  // PB min regl
  min_regl_fi: 85, // en %
  min_regl_tech: 90, // en %
  min_regl_prime_prev: 4.5, // en %
}
// model point list
export const paramGeneSlice = createSlice({
  name: 'parametres_generaux',
  initialState,
  reducers: {
    update: (state, action) => ({ ...state, [action.payload.id]: action.payload.value }),
    update_all: (state, action) => action.payload,
  },
})

export const { update, update_all } = paramGeneSlice.actions
export const getParamGene = (state) => state.paramGene

export default paramGeneSlice.reducer
